import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../services/login.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
loginForm:FormGroup;
  errorMessage: any;
  constructor(private formBuilder:FormBuilder,private loginService:LoginService,private router:Router) {

 
     this.loginForm = formBuilder.group({
      username:['',Validators.required],
      password:['',Validators.required]
    });
   }

  ngOnInit(): void {

  }

  onLoginSubmit() {
    console.log(this.loginForm.value);
    let loginUserDetails ={
      "user_type_id": 1,
      "username": this.loginForm.value.username,
      "password": this.loginForm.value.password,
      // "id": 0
    }
    this.loginService.loggedInUser(loginUserDetails).subscribe((logindata:any) =>{
      console.log(logindata.responsedata.status)
      if(logindata.responsedata.status ==='failed') {
        this.errorMessage = logindata.responsedata.error;
        localStorage.setItem('isLoggedIn',"false");
        sessionStorage.removeItem('token');
      } else {
        this.errorMessage ='';
        let loginData11 =logindata.responsedata;
        console.log(loginData11);
        sessionStorage.setItem('token',logindata.responsedata.data.token);
        localStorage.setItem('loginuserData',JSON.stringify(logindata.responsedata.data));
        localStorage.setItem('isLoggedIn',"true");
        debugger;
      let getUrl = window.location;
      let baseUrl = getUrl.origin;
      console.log(baseUrl,222)
            window.location.href = baseUrl;
      }
     
    })
  }

}
