import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { PartnerComponent } from './core/components/partner/partner.component';
import { ContactComponent } from './core/components/contact/contact.component';
import { FooterLinksComponent } from './core/components/footer-links/footer-links.component';
import { HomeComponent } from './core/components/home/home.component';
import { LoginComponent } from './core/components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RegisterComponent } from './core/components/register/register.component';
import { ContactusComponent } from './core/components/contactus/contactus.component';
import { MakePaymentComponent } from './core/components/make-payment/make-payment.component';
import { FranchiseeComponent } from './core/components/franchisee/franchisee.component';
import { HotelSearchComponent } from './core/components/hotel-search/hotel-search.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HotelResultComponent } from './core/components/hotel-result/hotel-result.component'; 
import { NgxPaginationModule } from 'ngx-pagination';
import { HotelDetailsComponent } from './core/components/hotel-details/hotel-details.component';
import { AgmCoreModule } from '@agm/core';
import { HotelReviewComponent } from './core/components/hotel-review/hotel-review.component';
import { TravellerDetailsComponent } from './core/components/traveller-details/traveller-details.component';
import { ReviewBookingdetailsComponent } from './core/components/review-bookingdetails/review-bookingdetails.component';
import { OnlinePaymentComponent } from './core/components/online-payment/online-payment.component';
import { InvoiceComponent } from './core/components/invoice/invoice.component';
import { Ng5SliderModule } from 'ng5-slider';
import { dateFormatPipe } from '../app/core/utilities/pipe/DatePipe'
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PartnerComponent,
    ContactComponent,
    FooterLinksComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    ContactusComponent,
    MakePaymentComponent,
    FranchiseeComponent,
    HotelSearchComponent,
    HotelResultComponent,
    HotelDetailsComponent,
    HotelReviewComponent,
    TravellerDetailsComponent,
    ReviewBookingdetailsComponent,
    OnlinePaymentComponent,
    InvoiceComponent,
    dateFormatPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng5SliderModule,
    BsDatepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
