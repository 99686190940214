<header id="header">
    <div class="top-bar">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="top-number"><p><i class="fa fa-phone-square"></i>  +0123 456 70 90</p></div>
                </div>
                <div class="col-sm-6">
                    <div class="social" *ngIf="!isLoggedin">
                        <ul class="social-share">
                        <li><a style="padding:0 10px;" [routerLink]="['/login']">Login</a></li>
                        <li><a [routerLink]="['/signup']" style="padding:0 10px;">SignUp</a></li>
                        
                        
                            <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fa fa-linkedin"></i></a></li> 
                            <li><a href="#"><i class="fa fa-google-plus "></i></a></li>
                            <li><a href="#"><i class="fa fa-skype"></i></a></li>
                        </ul>
                        
                   </div>
                   <div class="social" *ngIf="isLoggedin">
                        <ul class="social-share">
                        <li class="white margin-left10px" ><a style="padding:0 10px;">Credit Balance <i class="fa fa-inr"></i>{{userWalletDetails?.wallet_amount}}</a></li>
                     
                       <li class="dropdown">
                                        <a class="pointer" data-toggle="dropdown"><i class="fa fa-wrench  fa-fw fa-1x"></i><span>Support</span> <span class="caret"></span></a>
                                        
                                        <ul class="dropdown-menu">
                                            <li><a [routerLink]="['/contact']">Contact Us</a></li>
                                                <li class="submenu-option"><a >Relationship Manager</a>
                                                    <div class="container submenu ">

                                                        <div class="col-sm-12" style="margin-left: -22px;">
<h6 align="left" style="float:left;margin-top: 5px;"><strong>RelationShip Manager</strong></h6>
<table>
    <tr>
        <td><strong>Name:-</strong></td>
        <td>yogesh kumar</td>
    </tr>
    <tr>
        <td><strong>Email:-</strong></td>
        <td>yogesh.shukla097@gmail.com</td>
    </tr>
    <tr>
        <td><strong>Contact:-</strong></td>
        <td>1233455666</td>
    </tr>
</table>
</div>                                                        </div> </li>

                                            <li><a [routerLink]="['/make-payment']">Make Payment</a></li>
                                            <li><a href="#">Talk to Us</a></li>
                                        </ul>
                                    </li>
                        
                        <li><a style="padding:0 10px;" href="https://b2b.bookingcabs.com" target="_blank"><i class="fa fa-user fa-fw fa-1x"></i><span>Parter/Suppliers Login</span></a></li>
                        
                        <li style="display:none;"><a  href="b2b_ragistration.html" style="padding:0 10px;">SignUp</a></li>
                        
                        
                            <!--<li><a href="#"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fa fa-linkedin"></i></a></li> 
                          <li><a href="#"><i class="fa fa-google-plus "></i></a></li>
                            <li><a href="#"><i class="fa fa-skype"></i></a></li>-->
                        </ul>
                        
                   </div>
                </div>
            </div>
        </div><!--/.container-->
    </div><!--/.top-bar-->

  
        <div class="container">
            <div class="navbar-header col-sm-4 padding0">
                
                <a class="navbar-brand" [routerLink]="['/']"><img src="assets/images/logo.png" alt="logo"></a>
            </div>
            
            <div class="col-sm-8 padding0 margin-top10">
            
             <div class="-col-sm-1 pull-right user-login"  *ngIf="isLoggedin">
                               <a class="header-icon"><i class="fa fa-heart-o" style="font-size:24px;color:#000; margin-top: 0px;"></i>                                                    <div class="compare-count">3</div>
                                        </a>

                                    <ul class="social-share" style="z-index: 999999">
                                        <li>
                                            <a class="header-icon" href="#"><i class="fa fa-balance-scale" style="font-size:24px;color:#000; margin-top: 0px;"></i>
                                                <div class="compare-count">0</div>
                                            </a>
                                        </li>
                                         <li class="dropdown">

                                            <a href="" data-toggle="dropdown" class="bgnone  pull-right left linhight60">
                                                <div class="control-label textlogin">
                                                    <span id="" style="line-height: 27px; padding:0 10px;">{{userDetails?.first_name}}</span> <span class="caret"></span>
                                                </div>
                                            </a>

                                            <ul class="dropdown-menu">

                                                <li>
                                                    <a href="/dashboard" class="bgnone  pull-right center">
                                                        <div class="control-label textlogin" style="float: left; padding-left: 5px;">
                                                            <span id="">My Account</span>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li><a (click)="onLogout()" class="bgnone  pull-right center">
                                                        <div class="control-label textlogin" style="float: left; padding-left: 5px;">
                                                            <span id="" >Logout</span>
                                                        </div>
                                                    </a></li>



                                        </ul>
                                    </li>
                                    <li>
                                             <a class="header-icon" href="#">
                                      <i class="fa fa-shopping-cart" style="font-size:24px;color:#000; margin-top: 0px;"></i>
                                                <div class="compare-count">0</div>
                                            </a>
                                      </li>
                                </ul>
                            </div>
            
            
           
                <ul class="topmenu">
                 <!-- <li id="login"><a href="#modal" class="carrantel">Login</a></li> -->
                 
                 <li><a [routerLink]="['/franchisee']" class="carrantel" target="_blank">Apply Franchisee</a></li> 
               
                     <!--                                           
                    <li><a href="#">My Account</a></li> 
                  <li><a href="#">Refer & Earn </a></li>
                   <li><a href="#"> Offer</a></li>
                   
                    <li><a href="#">About Us</a></li>
                    
                     <li class="active"><a href="#">Home</a></li>  -->                     
                </ul>
                
            </div>
             
        </div><!--/.container-->
    <!--/nav-->
    
</header>

<section id="screen1">
    <nav class="navbar navbar-inverse topbar " role="banner" style="background:#eee;">
             <div class="container">
                 <div class="navbar-header">
                     <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                         <span class="sr-only">Toggle navigation</span>
                         <span class="icon-bar"></span>
                         <span class="icon-bar"></span>
                         <span class="icon-bar"></span>
                     </button>
                     
                 </div>
                 
                 <div class="collapse navbar-collapse navbar-left padding0">
                     <ul class="nav navbar-nav ">
                         <li  >
                             <a routerLinkActive="active" [routerLink]="['/']"><i class="fa fa-home fa-6"></i></a>
                            </li>
                         <li><a routerLinkActive="active" class="modal_flaghtandhotelsearch">Tour Package</a></li>
                          <li><a href="#">Customize Package</a></li>
                         <!-- <li  class="dropdown"><a href="#" class="dropdown-toggle" data-toggle="dropdown">Holiday Distinations<i class="fa fa-angle-down"></i></a>
                         <ul class="dropdown-menu">
                                 <li><a href="result_holidaydesti.html">America</a></li>
                                 <li><a href="result_holidaydesti.html">Asia</a></li>
                                 <li><a href="result_holidaydesti.html">Europe</a></li>
                                 <li><a href="result_holidaydesti.html">Shortcodes</a></li>
                             </ul>
                         </li> -->
                         <li><a routerLinkActive="active" [routerLink]="['/hotel-search']">Hotel Search</a></li>
                        
                         <li class="dropdown">
                             <a routerLinkActive="active" class="dropdown-toggle" data-toggle="dropdown">Group Holiday <i class="fa fa-angle-down"></i></a>
                             <ul class="dropdown-menu">
                                  <li><a href="americas_groups.html">America</a></li>
                                   <li><a href="americas_groups.html">Africa and Middle East</a></li>
                                 <li><a href="americas_groups.html">Asia</a></li>
                                 <li><a href="americas_groups.html">Europe</a></li>
                                 <li><a href="americas_groups.html"> Islands</a></li>
                                  <li><a href="americas_groups.html"> Australia and New Zealand</a></li>
                                 <li><a href="americas_groups.html">Indian Subcontinent</a></li>
                                 <li><a href="americas_groups.html"> Marathi Group Holidays</a></li>
                                 <li><a href="americas_groups.html">  Gujarati Group Holidays</a></li>
                             </ul>
                         </li>
                          <li class="dropdown">
                             <a href="#" class="dropdown-toggle" data-toggle="dropdown">Customised Holidays <i class="fa fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                  <li><a href="americas_customised.html">America</a></li>
                                   <li><a href="americas_customised.html">Africa and Middle East</a></li>
                                 <li><a href="americas_customised.html">Asia</a></li>
                                 <li><a href="americas_customised.html">Europe</a></li>
                                 <li><a href="americas_customised.html"> Islands</a></li>
                                  <li><a href="americas_customised.html"> Australia and New Zealand</a></li>
                                 <li><a href="americas_customised.html">Indian Subcontinent</a></li>
                                 <li><a href="americas_customised.html"> Marathi Group Holidays</a></li>
                                 <li><a href="americas_customised.html">  Gujarati Group Holidays</a></li>
                             </ul>
                         </li>
                          <li class="dropdown">
                             <a href="#" class="dropdown-toggle" data-toggle="dropdown">Holiday Themes <i class="fa fa-angle-down"></i></a>
                             <ul class="dropdown-menu">
                                  <li><a href="adventure.html">Adventure</a></li>
                                 <li><a href="adventure.html">Family</a></li>
                                 <li><a href="adventure.html">Weekend Getaways</a></li>
                                 <li><a href="adventure.html">Holidays of India</a></li>
                                  <li><a href="adventure.html">Honeymoon</a></li>
                                 <li><a href="adventure.html">Popular</a></li>
                                 <li><a href="adventure.html">Holidays of India</a></li>
                                 
 
     
 
                             </ul>
                         </li>
                                            
                     </ul>
                 </div>
             </div><!--/.container-->
         </nav>
         
         </section>