import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isLoggedin:boolean=false;
  userDetails: any;
  currentRoute:'Home'
  userWalletDetails: any;
  
  constructor(private cdr:ChangeDetectorRef,private router:Router,activateRoute:ActivatedRoute, private waletService:WalletService) {  
  
  }

  ngOnInit(): void {
    this.isLoggedin = Boolean(localStorage.getItem('isLoggedIn'));
    this.userDetails = JSON.parse(localStorage.getItem('loginuserData'));
    if(this.userDetails){
      this.waletService.walletAmountByUserID(this.userDetails.user_id).subscribe((data:any) =>{
        this.userWalletDetails =data.responsedata.data;
        });
    }
    
  }

  onLogout(){
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('loginuserData');
    localStorage.removeItem('token');
    let getUrl = window.location;
    let baseUrl = getUrl.origin;
    window.location.href =baseUrl;
  }

}
