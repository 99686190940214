import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  baseurl =environment.api;
  constructor(private http:HttpClient) { }

  getCMSpageByID(id:number) {
    let api_url =`${this.baseurl}cms-pages/${id}`
    return this.http.get(api_url);
  }
}
