import { flatten } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { data } from 'jquery';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.scss']
})
export class OnlinePaymentComponent implements OnInit {
  roomDataObj: any;
  hotelRoomDetails: any;
  hotelDataObj: any;
  searchDataObj: any;
  isLoggedin: boolean;
  userDetails: any;
 
  userWalletDetails: any;
  isOnlinePayment:boolean =false;
  isSelectCreditShell: boolean = false;
  onlinePaymentType: any ='Credit';
  finalPayment: any;

    constructor(private waletService:WalletService) {
    this.isLoggedin = Boolean(localStorage.getItem('isLoggedIn'));
    this.userDetails = JSON.parse(localStorage.getItem('loginuserData'));
    if(this.userDetails){
      this.waletService.walletAmountByUserID(this.userDetails.user_id).subscribe((data:any) =>{
        this.userWalletDetails =data.responsedata.data;
        });
    }
   }

  ngOnInit(): void {
    this.isOnlinePayment =false;
    this.roomDataObj =  JSON.parse(localStorage.getItem('roomData'));
    this.hotelRoomDetails =  JSON.parse(localStorage.getItem('roomData'));
    this.searchDataObj = JSON.parse(localStorage.getItem('userSearchData'));
    this.hotelDataObj = JSON.parse(localStorage.getItem('hotelData'));
    this.finalPayment = this.hotelRoomDetails.Price.PublishedPriceRoundedOff * this.searchDataObj.NoOfNights * this.searchDataObj.NoOfRooms;
 
  }

  showStarRating(rating) {
    return Array(rating).fill(0).map((x,i)=>i);
  }

  onOnlinePayment(event) {
    if(event.target.checked) {
      this.isOnlinePayment = true; 
    } else {
      this.isOnlinePayment = false;
    }
    
  }

  onSelectCrediShell() {
    this.isSelectCreditShell = !this.isSelectCreditShell;
  }

  OnSelectOnlinePaymentType(paymentMethod){
    if(paymentMethod ==='Atom') {
      let txnDetail = {
        "login": "192",
        "pass": "Test@123",
        "ttype": "NBFundTransfer",
        "prodid": "NSE",
        "amt": this.finalPayment,
        "txncur": "INR",
        "txnamt": this.finalPayment,
        "clientcode": "NAVIN",
        "transid": "1000",
        "datepick": "01/03/2019 16:20:00",
        "custacc": "100000036600",
        "udf1": "Rakesh Gosawami",
        "udf2": "ankit.sharma@atomtech.in",
        "udf3": "8446320942",
        "udf4": "Ft tower, Andheri, Mumbai",
        "ru": "http://localhost:3000/api/v1/payment/atomPaymentResponse"
      }
      this.waletService.atomPayment(txnDetail).subscribe((data:any) =>{
        window.location.href = data.response.data;
      })
    } else {
      this.onlinePaymentType =paymentMethod;
    }  
  }

  onWalletPayment() {
    let updatedWalletAmount = this.userWalletDetails.wallet_amount - this.finalPayment
    let walletDetails=
      {
        "user_id":this.userDetails.user_id,
        "wallet_amount": updatedWalletAmount.toString(),
      }
    this.waletService.updateWalletAmount(walletDetails).subscribe(data =>{
    })
  }

}
