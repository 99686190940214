<div class="col-md-3 col-sm-6">
    <div class="widget">
        <h3>GET IN TOUCH</h3>
        <ul>
            <li>
            <strong>Address</strong><br>

            9 -30, D.B Gupta Market,
Karol Bagh New Delhi - 110005 (INDIA)</li>
            <li><strong>Contact No.</strong>: 
                +91-11-42424242
</li>
            <li>Email: <a href="#">info@regencytours.in</a></li>
            <li>Skype: <a href="#">regencytours</a></li>
            
           
        </ul>
    </div>    
</div><!--/.col-md-3-->

<div class="col-md-3 col-sm-6">
    <div class="widget">
        <h3>ABOUT COMPANY</h3>
        <ul>
           
            <li><a href="#">About Us</a></li>
             <li><a href="#">Copyright</a></li>
             <li><a href="#">Terms of use</a></li>
              <li><a href="#">Privacy policy</a></li>
               <li><a href="#">Contact Us</a></li>
            
           
        </ul>
    </div>    
</div><!--/.col-md-3-->

<div class="col-md-3 col-sm-6">
    <div class="widget">
        <h3>SUPPORT</h3>
        <ul>
             
            <li><a href="#">Faq</a></li>
            <li><a href="#">Documentation</a></li>
              <li><a href="#">Refund policy</a></li>
            <li><a href="#">Ticket system</a></li>
            <li><a href="#">Billing system</a></li>
        </ul>
    </div>    
</div><!--/.col-md-3-->

<div class="col-md-3 col-sm-6">
    <div class="widget">
        <h3>NEWWLETTER SIGN UP</h3>
        
            <input type="text" class="form-control newslatter" placeholder="Your Email ID"><br>
            <input type="button" value="Sign Up" class="newsletterbutton">
            
            
       
    </div>    
</div><!--/.col-md-3-->