<section id="main-slider" style="padding:0 0;">
  
    <div class="formbgnepal" > 
        <div class="container" *ngIf="isSearchClick || firstsearchclick">
            <div class="slide-margin">
                 <div class="search-tabs search-tabs-bg search-tabs-nobox search-tabs-lift-top">
        <div class="tabbable">              
            <div class="tab-content1">
        <div class="tab-pane fade in active" id="tab-5">
                              
            <form>
                <div class="row">
                    <div class="col-md-2 width15per">
                        <div class="form-group form-group1 form-group-lg form-group-icon-left">
                           <label><strong>Country</strong></label>
                            <span style="position: relative; display: block; direction: ltr;" class="twitter-typeahead"> 
                            {{selectedCity.COUNTRY}}
                             </span>
                        </div>
                    </div>
                    
                    <div class="col-md-2 width15per">
                        <div class="form-group form-group1 form-group-lg form-group-icon-left">
                           <label><strong>City</strong></label>
                            <span style="position: relative; display: block; direction: ltr;" class="twitter-typeahead">
                                {{selectedCity.originalText}}
                             </span>
                        </div>
                    </div>
                    
                    <div class="col-md-2 width15per">
                        <div class="form-group form-group1 form-group-lg form-group-icon-left">
                           <label> 	<strong>Check-in:</strong> </label>
                            <span style="position: relative; display: block; direction: ltr;" class="twitter-typeahead">
                             {{checkInDate | dateFormatPipe}}
                             </span>
                        </div>
                    </div>
                    
                                                        
                    <div class="col-md-2 width15per">
                        <div class="form-group form-group1 form-group-lg form-group-icon-left">
                           <label> 	<strong>Check-out:</strong> </label>
                            <span style="position: relative; display: block; direction: ltr;" class="twitter-typeahead">
                                {{checkOutDate | dateFormatPipe}}
                             </span>
                        </div>
                    </div>
                    
                    
                    <div class="col-md-2 width15per">
                        <div class="form-group form-group1 form-group-lg form-group-icon-left">
                           <label> 	<strong>No of days:</strong> </label>
                            <span style="position: relative; display: block; direction: ltr;" class="twitter-typeahead">
                                {{numbeOfNights}}
                             </span>
                        </div>
                    </div>
                    
                   <div class="col-md-1">
                                    <div class="form-group form-group1 form-group-lg form-group-icon-left">   
                                           <label> 	<strong>Rooms:</strong> </label> 
                       <span style="position: relative; display: block; direction: ltr;" class="twitter-typeahead">
                                        
                                         {{selectedRoomCount}}
                                         </span>
                                    </div>
                                </div>
                      <div class="col-md-1 col-md-1modify">
                                    <div class="form-group form-group1 form-group-lg form-group-icon-left">
                                        <div *ngIf="!isSearchClick" class="modify_text btn btn-primary1 btn-lg"  type="button" (click)="modifySearch()">Modify Search (+) </div>
                                        <div *ngIf="isSearchClick" class="modify_text btn btn-primary1 btn-lg"  type="button" (click)="modifySearch()">Modify Search (-) </div>
                                                                                           </div>
                                    </div>
                                </div>     
                    
               
                
            </form>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>            
    <div class="container" *ngIf="!isSearchClick">
                            <div class="slide-margin padding-top76px">
                            <div class="col-sm-10 margin-flot">
                                 <div class="search-tabs search-tabs-bg search-tabs-nobox search-tabs-lift-top">
                <div class="tabbable">
                    <h1>Hotel  Search</h1>
                    <div class="tab-content">
                        <div class="tab-pane fade in active hotel-search" id="tab-1">
                           
                            <form [formGroup]="hotelSearchForm">
                                <div class="row">
                                    <div class="col-md-3 padding-right1">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                        <div class="search">
                                            <input #filterInput type="text" maxlength="20" (keyup)='searchQueryOnDataSource($event)' dir="auto" formControlName="destination"class="typeahead form-control rt-input package-inbox" placeholder="Destination" type="text"> <i class="fa fa-map-marker inbox-icon"></i>
                                          
                                            <div class="autocomplete">
                                              <ul #filterList class="sub-list" *ngIf="resultDataList">
                                                <li class="btn btnsearch" *ngFor="let result of resultDataList" (click)="selectedResult(result)">
                                                    
                                                  <div *ngIf="resultDataList.length > 1" [innerHTML]='result?.DESTINATION'></div>
                                                  <div class="countryList" *ngIf="resultDataList.length > 1" [innerHTML]='result?.COUNTRY'></div>
                                                  <span *ngIf="resultDataList.length === 1" [innerHTML]='result?.DESTINATION'></span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>       
                                           

                                            
                                          
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="col-md-2 padding-right1">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                                   
                                           
                                            <input formControlName="fromDate" [minDate]="minDate" class="regencydatepicar form-control rt-input package-inbox" id="from" placeholder="Check in"autocomplete="off" type="text" bsDatepicker [(bsValue)]="newVar1">

                                            <!-- value="{{ newVar1 | date:'dd-MM-yyyy' }} -->
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="col-md-2 padding-right1">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                                   
                                           
                                            <input formControlName="toDate" class="regencydatepicar form-control rt-input package-inbox" id="to" placeholder="Check Out" type="text"autocomplete="off" bsDatepicker [(bsValue)]="newVar" [minDate]="minDateCheckOut">
                                            
                                           
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="col-md-1 col-md-1-hotelsearch padding-right1">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                            <input class="form-control rt-input package-inbox" formControlName="nightsnumber" id="to" placeholder="No of Nights" type="text">   
                                        </div>    
                                    </div>
                                    <div class="col-md-1 col-md-1-hotelsearch padding-right1">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                           <div class="select-style package-inbox">
   <select class="form-control rt-input1 package-inbox" (change)="onRoomSelection($event.target.value)" >
															<option selected="selected" value="1">1 Rooms</option>
															<option value="2">2 Rooms</option>
															<option value="3">3 Rooms</option>
															<option value="4">4 Rooms</option>
															<option value="5">5 Rooms</option>
																</select>
                                        
															
														</div>
                                        </div>
                                        
                                    </div>
                                    
                                  
                                                                       
                                    <div class="col-md-1 col-md-1-hotelsearch padding-right1">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                                   
                                           
                                           <div class="select-style package-inbox">
                                            <div class="package-inbox pointer guest-div"(click)="onShowGuest()"><label class="text-black pointer">Guest</label> <label class="text-black pointer">01</label></div>
</div>
                                    </div>
                                 
                                 <div class="col-sm-4 guest-dropdown" *ngIf="isShowGuestList" >                                 	
                                 
                                 <div class="addGuestDropDown dropDown" style="display: block;">
        <div class="blockWrapper">
            <ng-container *ngFor="let room of Rooms;let roomindex = index;">
            <div class="block individualRoomSection">
                <!-- style="display:none;" -->
            <div class="editBlock" > 
            <div class="edit" (click)="onRoomEdit(roomindex)">Edit</div>
            <div class="closeIcn" (click)="removeRoom(roomindex)" >x</div></div>

            <div class="roomsIndividualCount">Room {{roomindex+1}}</div>
                <div class="roomGuestCount roomGuestCountSummery">{{room.selectedAdult}} Adults<span class="roomChild"> ,{{room.selectedChild}} Children</span></div>
                <div class="roomGuestCount" *ngIf="isEditRoom === roomindex">
                    <div class="choose adult">
                        <div class="label">Adult</div>
                        <div class="number number-adult">
                            <ul >
                                <li *ngFor="let n of numSequenceAdult(room.adultmaxcount);let adultindex = index;" (click)="onAdultSelection(roomindex,adultindex)"[ngClass]="{ 'active': adultindex== room.selectedAdult-1 }">{{adultindex+1}}</li>
                                <!-- <li class="active">2</li> -->
                               
                            </ul>
                        </div>
                    </div>
                    <div class="choose child">
                        <div class="label">Children</div>
                        <div class="number number-child">
                            <ul>
                                <li *ngFor="let n of numSequenceChild(room.childmaxcount);let childindex = index;" (click)="onChildSelection(roomindex,childindex)"[ngClass]="{ 'active': childindex== room.selectedChild-1 }">{{childindex+1}}</li>
                            </ul>
                        </div>
                        <div class="closeIcn" >x</div>
                    </div>
                    <!-- style="display: none;" -->
                    <div class="childAgeWrap childAge1"  *ngFor="let n of numSequenceChild(room.selectedChild);let childcountindex = index;">
                        <div class="label">Age of child {{childcountindex+1}}</div>
                        <div class="ageList">
                            <ul>
                                <li *ngFor="let n of numSequenceChildAge(12);let childage = index;"  (click)="onClickChildAge(roomindex,childage+1,childcountindex)" [ngClass]="{ 'active': childage== room.childAge[childcountindex]-1 }" >{{childage+1}}</li>
                            </ul>
                        </div>
                    </div>
                  
                    
                </div>
            </div>
          </ng-container>     
        </div>
        <div class="actionWrap">
            <div class="addRoomButton" style="display: block;" (click)="addRoom()">Add Room</div>
            <div class="actionButton" (click)="roomSelectionDone()">Done</div>
        </div>
    </div>
                    </div></div>
                                 
                                 
                                      
 
  
  									<div *ngIf="!isAdvanceSearch" class="col-sm-1 padding-left0 search-web">
                                                <button class="btn btn-lg btn-tour1" [disabled]="!hotelSearchForm.valid" (click)="searchHotelList()" type="submit">Search</button> </div>
                                        </div>
                                        
                                 <div class="row advance-hotel-search" *ngIf="isAdvanceSearch">
                                    <div class="col-md-3 padding-right1">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                                 <div class="select-style package-inbox">
                                           
   <select name="ctl00$cph_main$roomsNo" id="cph_main_roomsNo" class="form-control rt-input1 package-inbox" formControlName="starcategroy">
															<option selected="selected" value="1">Star Categories</option>
															<option value="2">1 Star</option>
															<option value="3">2 Star</option>
															<option value="4">3 Star</option>
															<option value="5">4 Star</option>
                                                            <option value="5">5 Star</option>
																</select>
                                        
															
														</div>   
                                           
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="col-md-2 padding-right1">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                                   
                                           
                                           <div class="select-style package-inbox">
                                           
   <select name="ctl00$cph_main$roomsNo" id="cph_main_roomsNo" class="form-control rt-input1 package-inbox" formControlName="currency">
															<option selected="selected" value="1">Currency</option>
															<option value="2">INR</option>
															<option value="3">USD</option>
															<option value="4">EUR</option>
															<option value="5">GBP</option>
                                                            <option value="5">AED</option>
																</select>
                                        
															
														</div>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="col-md-2 padding-right1">
                                       <div class="form-group form-group-lg form-group-icon-left">
                                                   
                                           
                                           <div class="select-style package-inbox">
                                           
   <select name="ctl00$cph_main$roomsNo" id="cph_main_roomsNo" class="form-control rt-input1 package-inbox" formControlName="propertytype">
															<option selected="selected" value="1">Property Type</option>
															<option value="2">Hotel</option>
															<option value="3">Villas</option>
															<option value="4">Guesthouses</option>
															<option value="5">Apartment</option>
                                                            </select>
                                        
															
														</div>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                    
								</div>
                                
                                <div class="row">
                                
                                <div class="col-sm-1 padding-left0" *ngIf="isAdvanceSearch">
                                                <button [disabled]="!hotelSearchForm.valid"class="btn btn-lg btn-tour1" type="submit">Search</button> </div>
                                                
                                </div>
                                       
  
    								
  
  
  <div class="row">                                  
                                    <div class="col-md-12 ">
                                    
                                    
                                        <div class="input-daterange" data-date-format="M d, D">
                                            <div class="row">
                                            
                                            
                                            
                                             <label class="pull-right pointer" id="hotel-advance-search"   (click)="onAdvanceSearch()" *ngIf="!isAdvanceSearch">Advance Search</label>
                                             <label class="pull-right pointer" id="hotel-advance-hide" (click)="onAdvanceSearch()" *ngIf="isAdvanceSearch">Hide Search</label> 
                                            <div class="col-sm-8 padding0">
                                            	<label class="service-icon"><a [routerLink]="['/hotel-search']" class="btn active" title="Hotel Booking"><i class="fa fa-hotel"></i></a></label>
                                                <label class="service-icon"><a href="package-search.html" class="" title="Tour Packages"><i class="fa fa-umbrella"></i></a></label>
                                                <label class="service-icon"><a href="#" class="" title="Transfer"><i class="fa fa fa-car"></i></a></label>
                                                <label class="service-icon"><a href="#" class="" title="Sightseeing"><i class="fa fa-binoculars"></i></a></label>
                                                
                                            </div>
                                            
                                            
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                               
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
            
                            </div>
 						
                        </div>
                    </div>

    </div>
    </section>
    <!-- *ngIf="isHotelListData" -->
    <app-hotel-result  *ngIf="isHotelListData" [hotelList]="searchHotelListData">

    </app-hotel-result>
    <div *ngIf="!isHotelListData && firstsearchclick" class="loadercontainer">
	
        <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
             <circle cx="170" cy="170" r="160" stroke="#E2007C"/>
             <circle cx="170" cy="170" r="135" stroke="#404041"/>
             <circle cx="170" cy="170" r="110" stroke="#E2007C"/>
             <circle cx="170" cy="170" r="85" stroke="#404041"/>
        </svg>
        
    </div>